import React from "react";
import styled from "styled-components";

const StyledButton = ({ children, ...props }) => {
  return (
    <StyledButtonContainer {...props} className="p-2 uppercase py-3 cursor-pointer text-center w-60 rounded-xl bg-bradesAzul font-medium text-white">
      {children}
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled.button`
  background-color: #251463;
  color: white;
  font-family:"Goldplay";
  letter-spacing:0.1rem;
  font-weight: bold;
  border-radius: 8px;
  padding: 10px 18px;
  margin-bottom: 10px;
  margin-top:10px;
  max-width:280px;
  text-align:center;
  cursor: pointer;
  &:hover {
    background-color: #140745;
  }
`;

export default StyledButton;
