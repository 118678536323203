import React from "react";
import styled from "styled-components";
import perk1Image from "../assets/1v2.svg";
import perk2Image from "../assets/2v2.svg";
import perk3Image from "../assets/3v2.svg";

const QueEs = ({href}) => {
  return (
    <QueEsContainer>
      <div className="flex">
        <div className="left">
          <h1>
            ¿En qué consiste <br /> SOS by Plerk?
          </h1>
          <p>
            Es una opción sencilla online que te permite solicitar y agendar servicios destinados para tu salud, hogar o
            vehículo en el momento que más lo necesites, <b>sin necesidad de requerir seguros adicionales.</b>
          </p>
          <a href={href}><StyledButton type="submit">¡Empieza ya!</StyledButton></a>
        </div>
        <div className="right">
          <div className="perk">
            <img src={perk1Image} alt="Médicos" />
            <div className="body">
              <p className="title">Médicos:</p>
              <p className="description">
                Asistencia médica de múltiples especialidades a tu alcance, en cualquier momento y desde cualquier
                lugar.
              </p>
            </div>
          </div>
          <div className="perk">
            <img src={perk2Image} alt="Nutriólogos" />
            <div className="body">
              <p className="title">Nutriólogos:</p>
              <p className="description">
                Estás a un paso de mejorar tu alimentación y mejorar tu salud, hazlo de la mano de nuestro equipo de
                nutriólogos y alcanza tus objetivos.
              </p>
            </div>
          </div>
          <div className="perk">
            <img src={perk3Image} alt="Psicólogos" />
            <div className="body">
              <p className="title">Psicólogos:</p>
              <p className="description">
                La salud mental y el bienestar emocional nos abre caminos para ser más exitosos en nuestro entorno
                laboral.
              </p>
            </div>
          </div>
        </div>
      </div>
    </QueEsContainer>
  );
};

const QueEsContainer = styled.div`
  background-color: #fff;
  width: 100%;
  color: #0d0838;
  padding: 52px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    gap: 150px;
    @media (max-width: 1020px) {
      flex-direction: column;
    }
  }
  .left {
    flex: 1;
    h1 {
      font-weight: 600;
      font-size: 42px;
      line-height: 129%;
      color: #251463;
    }
    p {
      font-weight: 500;
      font-size: 21px;
      line-height: 143%;
      color: #0d0838;
    }
  }

  .right {
    flex: 1;

    .perk {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: 20px 0px;

      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 143%;
        color: #0d0838;
        text-align: left;
      }

      .description {
        font-weight: 500;
        font-size: 17px;
        line-height: 143%;
        color: #0d0838;
      }

      img {
        max-width: 80px;
      }
    }
  }
`;

const StyledButton = styled.button`
  background-color: #251463;
  color: white;
  font-family:"Goldplay";
  letter-spacing:0.1rem;
  font-weight: bold;
  border-radius: 8px;
  padding: 12px 66px;
  margin-bottom: 10px;
  margin-top:10px;
  max-width:280px;
  text-align:center;
  cursor: pointer;
  &:hover {
    background-color: #140745;
  }
`;

export default QueEs;
