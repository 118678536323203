import React from "react";
import styled from "styled-components";
import perk1Image from "../assets/beneficio1.svg";
import perk2Image from "../assets/beneficio2.svg";
import perk3Image from "../assets/beneficio3.svg";
import perk4Image from "../assets/beneficio4.svg";
import tarjeta from "../assets/tarjeta.png";

const QueEsPlerk = () => {
  return (
    <QueEsContainer>
      <div className="flex">
        <div className="left">
          <img src={tarjeta} alt="tarjeta plerk" />
        </div>
        <div className="right">
          <span className="title">¿Qué es Plerk?</span>
          <br />
          <span className="description">
            Es la única <b>Membresía</b> internacional <span className="purple">todo en uno</span> de beneficios para
            tus colaboradores, que incluye:
          </span>
          <div className="perk">
            <img src={perk1Image} alt="Tarjeta digital recargable" />
            <div className="body">
              <p className="bold">Tarjeta digital recargable</p>
              <p>Tarjeta de compensaciones validada por Mastercard o Visa</p>
            </div>
          </div>
          <div className="perk">
            <img src={perk2Image} alt="Salud SOS by Plerk" />
            <div className="body">
              <p className="bold">Salud SOS by Plerk</p>
              <p>Atención médica, salud dental y asistencia telemedica ilimitada.</p>
            </div>
          </div>
          <div className="perk">
            <img src={perk3Image} alt="Descuentos exclusivos en marcas aliadas" />
            <div className="body">
              <p className="bold">Descuentos exclusivos en marcas aliadas</p>
              <p>Alianzas con hasta 50% de descuento en productos y servicios.</p>
            </div>
          </div>
          <div className="perk">
            <img src={perk4Image} alt="Gympass Starter" />
            <div className="body">
              <p className="bold">Gympass Starter</p>
              <p>Pases exclusivos para gimnasios, clases de yoga y/o meditación.</p>
            </div>
          </div>
        </div>
      </div>
    </QueEsContainer>
  );
};

const QueEsContainer = styled.div`
  background-color: #fff;
  width: 100%;
  color: #0d0838;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media (max-width: 1020px) {
      flex-direction: column;
    }
  }
  .left {
    flex: 1;
    img {
      width: 90%;
    }
  }

  .right {
    flex: 1;
    @media (max-width: 1020px) {
      padding: 0 40px;
    }

    .title {
      font-weight: 600;
      font-size: 42px;
      line-height: 120%;
      color: #6339f5;
    }

    .description {
      font-weight: 500;
      font-size: 24px;
      line-height: 130%;
      color: #0d0838;
    }

    .purple {
      color: #6339f5;
      font-style: italic;
      font-weight: 600;
    }

    .perk {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      padding: 20px 0px;

      img {
        max-width: 80px;
      }
    }
  }
`;

export default QueEsPlerk;
