import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sosByPlerk from "../assets/sosbyplerk.svg";
import fb from "../assets/social-media/fb.svg";
import ig from "../assets/social-media/ig.svg";
import linkedin from "../assets/social-media/in.svg";
import yt from "../assets/social-media/yt.svg";
import trustPilot from "../assets/trustpilot.svg";

const Footer = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1020;
  const isResponsive = width < breakpoint;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <FooterContainer>
      {!isResponsive && (
        <div className="first">
          <img src={sosByPlerk} alt="sos by plerk logo" />
          <span>
            Es la <b>Membresía de beneficios</b>
            <br /> para tus colaboradores
            <br />
            ubicados en todo el mundo
          </span>
        </div>
      )}
      {!isResponsive && (
        <div className="second">
          <div className="images">
            <div>
              <a href="https://www.facebook.com/plerk.io" target="blank">
                <img src={fb} alt="facebook" />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/plerkhq/" target="blank">
                <img src={ig} alt="instagram" />
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/Plerk" target="blank">
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
            <div>
              <a href="https://www.youtube.com/channel/UCT6i0NZ71saqwwEvsiMPTjQ" target="blank">
                <img src={yt} alt="youtube" />
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="third">
        <a href="https://es.trustpilot.com/review/plerk.io" target="blank">
          <img src={trustPilot} alt="trust pilot logo" />
        </a>
        <div>
          <span>Contacto:</span>
          <span>soporte@plerk.io</span>
          <span>© 2022 Plerk es propiedad de Isibit, Inc.</span>
        </div>
      </div>
      {isResponsive && (
        <div className="first">
          <img src={sosByPlerk} alt="sos by plerk logo" />
          <span>
            Es la <b>Membresía de beneficios</b>
            <br /> para tus colaboradores
            <br />
            ubicados en todo el mundo
          </span>
        </div>
      )}
      {isResponsive && (
        <div className="second">
          <div className="images">
            <div>
              <a href="https://www.facebook.com/plerk.io" target="blank">
                <img src={fb} alt="facebook" />
              </a>
            </div>
            <div>
              <a href="https://www.instagram.com/plerkhq/" target="blank">
                <img src={ig} alt="instagram" />
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/Plerk" target="blank">
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
            <div>
              <a href="https://www.youtube.com/channel/UCT6i0NZ71saqwwEvsiMPTjQ" target="blank">
                <img src={yt} alt="youtube" />
              </a>
            </div>
          </div>
        </div>
      )}
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  background-color: #251463;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 0.9rem;
  padding: 32px 89px;
  @media (max-width: 1020px) {
    flex-direction: column;
  }
  .first {
    display: flex;
    flex-direction: column;
    gap: 65px;
  }
  .second {
    .images {
      display: flex;
      flex-direction: row;
      gap: 18px;
    }
  }
  .third {
    display: flex;
    flex-direction: column;
    gap: 65px;
    & div {
      display: flex;
      flex-direction: column;
      text-align: right;
    }
    img {
      width: 100%;
    }
  }
  p {
    text-align: center;
  }

  a {
    text-decoration: none;
    color: white;
  }
`;

export default Footer;
