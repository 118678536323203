import styled from "styled-components";
import ComoUso from "./landing2/ComoUso";
import Header from "./landing2/Header";
import QueEs from "./landing2/QueEs";
import Footer from "./landing2/Footer";
import Navbar from "./components2/Navbar";
import QueEsPlerk from "./landing2/QueEsPlerk";

function App2() {
  return (
    <AppContainer>
      <Navbar />
      <Header id="formulario" />
      <QueEs href="#formulario" />
      <ComoUso href="#formulario" />
      <QueEsPlerk />
      <Footer />
    </AppContainer>
  );
}

const AppContainer = styled.div``;

export default App2;
