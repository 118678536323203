import React from "react";
import styled from "styled-components";
import step1Image from "../assets/1.svg";
import step2Image from "../assets/2.svg";
import step3Image from "../assets/3.svg";
import background from "../assets/bgcomousar.png";

const ComoUso = ({href}) => {
  return (
    <ComoUsoContainer>
      <div className="flexSection">
        <h1>¿Cómo uso SOS by Plerk?</h1>
        <div className="stepsContainer">
          <div className="step">
            <img src={step1Image} alt="1" />
            <br />
            <p className="bold big">Ingresa a tu cuenta</p>
            <p>Utiliza tu usuario y contraseña con los que te registraste.</p>
          </div>
          <div className="step">
            <img src={step2Image} alt="2" />
            <br />
            <p className="bold big">Entra a “Mis productos”</p>
            <p>Y da click en la asistencia que necesites para ver tus beneficios.</p>
          </div>
          <div className="step">
            <img src={step3Image} alt="3" />
            <br />
            <p className="bold big">Solicita tu servicio</p>
            <p>Selecciona el servicio deseado, llena los datos requeridos ¡y listo!</p>
          </div>
        </div>
        <a href={href}><WhiteButton>¡Empieza ya!</WhiteButton></a>
      </div>
    </ComoUsoContainer>
  );
};

const ComoUsoContainer = styled.div`
  background: #251463;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: 95% 0;
  padding: 69px 0 56px;
  h1 {
    @media (max-width: 500px) {
      text-align: center;
      padding: 0px 10px;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  .flexSection {
    flex-direction: column;
    padding: 20px 0px;
  }

  .stepsContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1080px) {
      flex-direction: column;
    }

    .step {
      flex: 1;
      padding: 5px 15px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      justify-content: center;
      text-align: center;

      img {
        max-width: 100px;
      }
      .big {
        font-size: 18px;
      }
    }
  }
`;

const WhiteButton = styled.button`
  background-color: #fff;
  color: #251463;
  font-family: "Goldplay";
  letter-spacing: 0.1rem;
  font-weight: bold;
  font-size: 16px;
  border-radius: 16px;
  padding: 16px 89px;
  margin-bottom: 10px;
  margin-top: 66px;
  max-width: 310px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }
`;

export default ComoUso;
