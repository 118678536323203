import React from "react";
import styled from "styled-components";
import sosByPlerk from "../assets/sosbyplerk.svg";

const Navbar = () => {
  return (
    <StyledNavbarContainer>
      <img src={sosByPlerk} alt="sos by plerk logo" />
    </StyledNavbarContainer>
  );
};

const StyledNavbarContainer = styled.button`
  width: 100%;
  background-color: #251463;
  border-bottom-width: 7px;
  border-bottom-style: solid;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-image: linear-gradient(90deg, #251463 0%, #6339F5 31%, #D9305B 55.24%, #FD6F7A 77.82%, #00FFF0 100%) 1;
  text-align: left;
  & img {
    padding: 10px 0 10px 81px;
    width: 210px;
    height: 37px;
  }
`;

export default Navbar;
