import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import heroBackground from "../assets/header2.png";
import StyledButton from "../components2/StyledButton";
import correoBg from "../assets/correo-input.svg";
import passwordBg from "../assets/password-input.svg";
import noMostrar from "../assets/ojo-password1.svg";
import mostrar from "../assets/ojo-password2.svg";

const Header = ({ id }) => {
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const term = queryParams.get("type");
    console.log(term);
    if (term === "2") {
      setLoginError(true);
    }
  }, []);

  return (
    <HeaderContainer>
      <form action={process.env.REACT_APP_URL_REDIRECCION} method="post">
        <div className="hero" style={{ height: "771px", backgroundImage: `url(${heroBackground})` }}>
          <div className="flex">
            <div className="container">
              <div className="left">
                <p className="title">
                  El respaldo que te
                  <br />
                  mereces{" "}
                  <span>
                    sin costo <br />
                    adicional
                  </span>
                </p>
                <p className="description">
                  Pensando en tu bienestar, creamos un portafolio completo de servicios integrales para acompañarte a
                  proteger lo más importante: <b>Tu salud, tu hogar y tus bienes.</b>{" "}
                </p>
              </div>
              <div className="right" id={id}>
                <h2 className="purple bold">Inicia sesión</h2>
                <span className="loginText">
                  Inicia sesión en tu cuenta de <b>SOS seguros</b> para acceder a la plataforma y comenzar a disfrutar
                  de todos sus beneficios.
                </span>
                <div className="correo">
                  {loginError && <div className="error">Verifique sus credenciales</div>}
                  <img src={correoBg} alt="contraseña" className="correo-img" />
                  <input
                    required
                    onChange={(e) => {
                      setCorreo(e.currentTarget.value);
                    }}
                    type="email"
                    placeholder="Correo"
                  />
                </div>
                <div className="input-password">
                  <div className="show-password" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <img src={mostrar} alt="mostrando contraseña" />
                    ) : (
                      <img src={noMostrar} alt="ocultando contraseña" />
                    )}
                  </div>
                  <img src={passwordBg} alt="contraseña" className="password" />
                  <input
                    required
                    onChange={(e) => {
                      setPassword(e.currentTarget.value);
                    }}
                    type={showPassword ? "text" : "password"}
                    placeholder="Contraseña"
                  />
                </div>
                <a href="https://www.plerk.io/login/forgot-password" target="blank"><p className="change-password">¿Olvidaste tu contraseña?</p></a>
                <StyledButton type="submit" style={{ maxWidth: "100%" }}>
                  Continuar
                </StyledButton>
              </div>
              <input type="hidden" name="email" value={correo} />
              <input type="hidden" name="password" value={password} />
            </div>
          </div>
        </div>
      </form>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fa1f1f;
    background-color: #ffe0e0;
    height: 30px;
    border-radius: 16px;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .hero {
    @media (max-width: 1080px) {
      height: 900px !important;
    }
    .flex {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10rem;
        padding: 0 90px;

        @media (max-width: 1080px) {
          flex-direction: column;
          gap: 1rem;
          padding: 0 10px;
        }

        .left {
          width: 70%;
          @media (max-width: 1080px) {
            width: 90%;
          }
          .title {
            font-weight: 500;
            font-size: 66px;
            line-height: 120%;
            color: #ffffff;
            text-align: left;
            & span {
              color: #00f3f3;
              font-weight: 600;
            }
            @media (max-width: 1080px) {
              font-size: 36px;
            }
          }
          .description {
            font-weight: 500;
            font-size: 27px;
            line-height: 48px;
            letter-spacing: 0.129032px;
            color: #ffffff;
            @media (max-width: 1080px) {
              font-size: 16px;
              line-height: 24px;
            }
          }

          img {
            max-width: 200px;
          }
          display: flex;
          flex-direction: column;
          color: white;
          align-items: flex-start;
          gap: 0.4rem;
        }

        .right {
          text-align: center;
          background: #ffffff;
          box-shadow: 2px 9px 32px rgba(0, 0, 0, 0.1);
          border-radius: 16px;
          padding: 40px 100px;
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 30%;
          .correo {
            position: relative;
            display: inline-block;
            .correo-img {
              position: absolute;
              top: 23px;
              left: 10px;
            }
          }

          @media (max-width: 1080px) {
            width: 90%;
          }

          @media (max-width: 500px) {
            padding: 20px 20px;
          }

          .change-password {
            font-weight: 600;
            font-size: 15px;
            line-height: 120%;
            text-decoration-line: underline;
            color: #1591ad;
          }
        }

        .show-password {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 10px;
          margin-top: 17px;
        }

        .input-password {
          position: relative;
          display: inline-block;
          .password {
            position: absolute;
            top: 18px;
            left: 10px;
          }
          img {
            width: 20px;
            &:hover {
              cursor: pointer;
            }
          }
        }

        input {
          width: 100%;
          padding: 12px 40px;
          margin: 8px 0;
          box-sizing: border-box;
          background-color: #f5f5f5;
          outline: none;
          border: none;
          font-family: "Goldplay";
          border-radius: 16px;
          &::placeholder {
            color: #838383;
            font-size: 18px;
            padding-left: 20px;
          }
        }

        input[type="email"] {
          /* background-image: url(${correoBg});
          background-repeat: no-repeat;
          background-size: 25px;
          background-position: left calc(-5% + 40px) bottom 50%;
          &:focus {
            background-image: none;
          } */
        }

        input[type="password"] {
          /* background-image: url(${passwordBg});
          background-repeat: no-repeat;
          background-size: 25px;
          background-position: left calc(-5% + 40px) bottom 50%;
          &:focus {
            background-image: none;
          } */
        }
      }
    }
  }
`;

export default Header;
